import React from "react"
import {getImageUrl} from "../../utils"
import styles from "./Me.module.css";


export const Me = () => {
    return <section className = {styles.container}>
        <div className = {styles.content}>
            <h1 className = {styles.title}>Hi, I'm Maggie!</h1>
            <div className={styles.sub}>
                <h4 className = {styles.subtitle}>
                    I'm currently a LSU Flores MBA candidate with concentrations in Analytics and Emerging Technology and a proud LSU Computer Science alumna!
                    <br></br><br></br>
                    After gaining experience in business process automation during an internship, I'm excited to continue exploring opportunities where business and technology intersect — let's connect!
                </h4>
            
                <a href="#footer" className = {styles.contactBtn}>Contact Me</a>
            </div>
        </div>

        <img src={getImageUrl("me/headshot.jpg")} className = {styles.meImg} alt="Image of Maggie Stewart"/>
        <div className = {styles.topBlur} />
        <div className = {styles.bottomBlur} />
    </section>
}