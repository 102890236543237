import React, { useState } from "react"
import {getImageUrl} from "../../utils"
import styles from "./Projects.module.css";
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"></link>


export const Projects = () => {
    return (
    <section className = {styles.container} id = "projects">
        <h2 className = {styles.title}>Projects</h2>

        <div className = {styles.content}>
            <div className = {styles.projectGrid}>
                <a href="https://github.com/maggiestewart/HonorsThesis" className = {styles.projectLink} target="_blank" rel="noopener noreferrer">
                    <div className={styles.projectItem}>
                        <img src={getImageUrl('/projects/LibraryBR.PNG')} className={styles.projectImage} />
                        <br></br>
                        <h3>LibraryBR - Honors Thesis</h3>
                        <h4>Advised by Dr. Qingyang Wang</h4>
                        <br></br>
                        <div className = {styles.iconGroup}>
                {/*                         
                            <div className={styles.iconItem}>
                                <img src={getImageUrl('icons/amazon-aws.jpg')} className={styles.icon} />
                                <i class="fa-brands fa-aws"></i>
                                <span class="icon"><i class="fa-brands fa-java"></i></span>
                                <h4>Amazon EC2</h4>
                            </div>
                            <div className={styles.iconItem}>
                                <img src={getImageUrl('icons/java.jpg')} className={styles.icon} />
                                <h4>Java Servlets</h4>
                            </div> */}
                        
                            <p className={styles.description}>
                            I designed and developed a <u>modern library website</u> using <strong>Amazon EC2 instances 
                            and Java Servlets</strong> that allows users to search the catalog and view upcoming events 
                            based on event audience.
                            <br></br><br></br>
                            I successfully defended my Honors Thesis with the LSU Ogden Honors College 
                            and graduated with <u><strong>College Honors</strong></u>, the highest honors distinction.
                            </p>
                        </div>
                    </div>
                </a>

                <a href="https://cxc.maggiestewart225.com" className = {styles.projectLink} target="_blank" rel="noopener noreferrer">
                    <div className={styles.projectItem}>
                        <img src={getImageUrl('/projects/CxCPortfolio.PNG')} className={styles.CxCImage} />
                        <br></br>
                        <h3>Distinguished Communicator Portfolio</h3>
                        <h4>Advised by Dr. Aisha Ali-Gombe</h4>
                        <br></br>
                    
                        <div className = {styles.iconGroup}>
    
                            <p className={styles.description}>
                            I designed and developed a <u>portfolio website from scratch</u> that showcases my undergraduate involvement   
                            and highlights both experiences that <strong>strengthened my communication skills</strong>.
                            <br></br><br></br>
                            I successfully passed the portfolio review process and graduated as a <u><strong>Distinguished 
                            Communicator</strong></u> as well as with the <u><strong>Communicator Certificate</strong></u>.
                            </p>
                        </div>
                    </div>
                </a>

                <a href="https://github.com/maggiestewart/AmazonExpenseAnalysis" className = {styles.projectLink} target="_blank" rel="noopener noreferrer">
                    <div className={styles.projectItem}>
                        <img src={getImageUrl('/projects/AmazonExpenseProject.png')} className={styles.CxCImage} />
                        <br></br>
                        <h3>Amazon Expense Analyzer</h3>
                        <br></br>
                    
                        <div className = {styles.iconGroup}>
                        
                            <p className={styles.description}>
                            Using <strong>Python and the pandas library</strong>, I analyzed my family's spending data from Amazon.com to show <u>visualized 
                                data</u> including our daily spending, total monthly spending, and average monthly spending costs.
                            <br></br> <br></br>
                            The published data and graphs use Data Quest's sample data set for data security purposes. 
                            </p>
                        </div>
                    </div>
                </a>

                <a href="https://github.com/maggiestewart/SRP" className = {styles.projectLink} target="_blank" rel="noopener noreferrer">
                    <div className={styles.projectItem}>
                        <img src={getImageUrl('/projects/ShiftReduceParserProject.png')} className={styles.projectImage} />
                        <br></br>
                        <h3>Shift Reduce Parser Calculator</h3>
                        <br></br>
                    
                        <div className = {styles.iconGroup}>
    
                            <p className={styles.description}>
                            Using <strong>HTML, CSS, and Javascript</strong>, I developed this program to parse any input expression 
                            based on the grammar provided and guide a user through <u>individual parsing steps</u> of the shift reduce parser. 
                            
                            </p>
                        </div>
                    </div>
                </a>

                <a href="" className = {styles.projectLink} target="_blank" rel="noopener noreferrer">
                    <div className={styles.projectItem}>
                        <img src={getImageUrl('/icons/Canva_logo.png')} className={styles.projectImageLogo} />
                        <br></br>
                        <h3>Canva Design Portfolio</h3>
                        <br></br>
                    
                        <div className = {styles.iconGroup}>
                        
                            <p className={styles.description}>
                                Using <strong>Canva</strong>, I designed flyers to market a variety of <u>events</u>, 
                                announce <u>important deadlines</u>, and enhance <u>presentations</u>.
                            </p>
                        </div>
                    </div>
                </a>

                <a href="https://maggiestewart225.com" className = {styles.projectLink} target="_blank" rel="noopener noreferrer">
                    <div className={styles.projectItem}>
                        <img src={getImageUrl('/projects/logo192.png')} className={styles.projectImageLogo} />
                        <br></br>
                        <h3>Personal Website</h3>
                        <br></br>
                    
                        <div className = {styles.iconGroup}>
                        
                            <p className={styles.description}>
                                Using <strong>React</strong>, I developed this website to showcase my current and past experiences. 
                                It serves as a <u>visual narrative</u> of my academic and professional journey, as well as my interests beyond the Computer Science field.
                            </p>
                        </div>
                    </div>
                </a>

            </div>
        </div>
    </section>
    );
};