
import styles from './App.module.css'
import { Navbar } from './components/navbar/Navbar.jsx'
import { Me } from './components/me/Me.jsx'
import { About } from './components/about/About.jsx'
import { Experience } from './components/experience/Experience.jsx'
import { Projects } from './components/projects/Projects.jsx'
import { Interests } from './components/interests/Interests.jsx'
import { Footer } from './components/footer/Footer.jsx'

function App() {
  
  return (
    <div className={styles.App}>
      <Navbar />
        <Me />
        <About />
        <Experience />
        <Projects />
        <Interests />
      <Footer />
    </div>
  );
}

export default App;
