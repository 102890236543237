import React, { useState } from "react"
import {getImageUrl} from "../../utils"
import styles from "./Experience.module.css";


export const Experience = () => {
    const [hoveredItem, setHoveredItem] = useState(null);
    
    const images = {
        cxc: getImageUrl("me/cxc-grad.jpg"),
        default: getImageUrl("me/grad pics-57.jpg")
    };
    
    return (
    <section className = {styles.container} id = "experience">
        <h2 className = {styles.title}>Experience</h2>
        <div className = {styles.content}>
            <ul className = {styles.experienceItems}>
                <li className = {styles.experienceItem}>
                    <img src={getImageUrl("icons/geaux-communicate.png")} className = {styles.experienceIcons} alt = "LSU CxC Geaux Communicate logo" />
                    <div className = {styles.experienceText}>
                        <h3>LSU CxC - Graduate Assistant</h3>
                        <h4>August 2024 - Present</h4>
                        <p>
                            🏆 Distinguished Communicator Graduate
                            <br></br>
                            📂 Manage program data to identify actionable insights and effyectively address data anomalies.
                            <br></br>
                            ⚙️ Leverage background in Computer Science to develop automated processes.

                        </p>
                    </div>
                </li>

                <li className = {styles.experienceItem}>
                    <img src={getImageUrl("icons/BBP-Logo-White.png")} className = {styles.experienceIcons} alt = "BBP Logo" />
                    <div className = {styles.bbpText}>
                        <h3>BBP Sales - ERP Software Developer Intern</h3>
                        <h4>December 2023 - August 2024</h4>
                        <p>
                            ⏩ Streamlined the transition from legacy to updated ERP systems by integrating user feedback.
                            <br></br>
                            🖋️ Engineered modern, intuitive UIs for Accounting and Sales modules.
                            <br></br>
                            ⚙️ Enhanced system performance by promoting scalable API integration.
                        </p>

                        <br></br> <br></br>

                        <h3>BBP Sales - Data Engineering & Business Automation Intern</h3>
                        <h4>June 2023 - May 2024</h4>
                        <p>
                            📈 Increased billing system capacity by 22 workdays/month by training 40 document scraping AI models.
                            <br></br>
                            💥 Eliminated 90% of inter-departmental requests by building a self-service API link between systems.
                            <br></br>
                            ⚙️ Automated commission payment query requests in external system by writing dynamic SQL queries.
                        </p>

                        <br></br> <br></br>

                        <h3>BBP Sales - Data Science & Web Development Intern</h3>
                        <h4>March 2023 - June 2023</h4>
                        <p>
                            📈 Extrapolated sensor data to develop machine learning models assessing data quality and accuracy.
                            <br></br>
                            🏷️ Configured and optimized Google Analytics tags to accurately track and analyze customer behavior.
                        </p>
                    </div>
                </li>

            </ul>
        </div>
    </section>
    );
};