import React, {useState} from "react";

import styles from "../navbar/Navbar.module.css";
import {getImageUrl} from "../../utils";

export const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    return <nav className={styles.navbar}>
        <a className = {styles.title} href="/">Maggie Stewart</a>
        <div className = {styles.menu}>
            <img className = {styles.menuBtn} 
                src={
                    menuOpen 
                        ? getImageUrl("nav/closeIcon.png") 
                        : getImageUrl("nav/menuIcon.png")
                    } 
                alt = "menu-button"
                onClick ={() => setMenuOpen(!menuOpen)}
            />
            <ul className = {`${styles.menuItems} ${menuOpen && styles.menuOpen}`}
            onClick={() => setMenuOpen(false)}
            >
                <li><a href="#education">Education</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#projects">Projects</a></li>
                <li><a href="#interests">Interests</a></li>
            </ul>
        </div>
    </nav>;
};