import React, {useState} from "react";

import styles from "../footer/Footer.module.css";
import {getImageUrl} from "../../utils";

export const Footer = () => {
    return <section className = {styles.outside} id = "footer">
        <nav className={styles.footer}>
            <div className = {styles.menu}>
                <ul className = {styles.menuItems}>
                    <li>
                        <a 
                            href="mailto:maggiestewartlsu@gmail.com"
                            rel="noopener noreferrer"
                            >
                            {/* <a target="_blank" href="https://icons8.com/icon/12623/email">Email</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> */}
                            <img src={getImageUrl('icons/icons8-email.png')} className = {styles.EmailIcon} />
                        </a>
                        .
                    </li>
                    <li>
                        <a 
                            href="https://github.com/maggiestewart" 
                            target = "_blank"
                            rel="noopener noreferrer" 
                            >
                            <img src={getImageUrl('icons/github-mark-white.png')} className={styles.menuIcon} />
                        </a>
                    </li>
                    <li>
                        <a 
                            href="https://www.linkedin.com/in/maggiestewart225/" 
                            target = "_blank"
                            rel="noopener noreferrer" 
                            >
                            <img src={getImageUrl('icons/In-White-72@2x.png')} className = {styles.LIIcon} />
                        </a>
                    </li>
                </ul>
            </div>
        <br></br>
        <h3 className = {styles.footerInfo}> © 2024 Margaret Stewart - Updated 10/2024</h3>
        </nav>
    </section>;
}